/* You can add global styles to this file, and also import other style files */
@use "@angular/cdk/overlay-prebuilt";
@use "definitions" as *;
@use "libs/shared/assets/styles" as *;

body {
    min-width: $min-body-width;
    min-height: auto;
    overflow-x: auto;
}

// TODO: potential shared style
.no-select {
    user-select: none;
}

.button {
    &--accept {
        color: lg-color(--white);
        background: lg-color(--base-60);

        &:hover {
            color: lg-color(--white);
            background: lg-color(--base-80);
        }
    }

    &--margin-right {
        margin-right: var(--spacing1);
    }
}

// TODO: fix icon alignment in condensed button in FW styles
a.button--condensed > lg-icon.lg-icon--inline {
    position: relative;
    top: -1px;
}
